import { memo } from 'react';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { LogoProps } from '../Logo/Logo';

function ClickableLogo(props: LogoProps) {
  return (
    <Link to="/">
      <Logo {...props} />
    </Link>
  );
}

export default memo(ClickableLogo);
